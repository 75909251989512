@import "coreui.min.css";
@import '~antd/dist/antd.css';

/*#3c4b64*/
.sweet-loading{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.ant-steps-navigation .ant-steps-item::before{
    background-color: #3c4b64;
}

button:focus{
    outline: none;
}

.col-xs-4 {
    color: white;
    font-size: 24px;
    padding-bottom: 20px;
    padding-top: 18px;
}
.menu-item {
    padding: 0 40px;
    margin: 5px 10px;
    user-select: none;
    cursor: pointer;
    border: none;
}
.menu-item-wrapper.active {
    border: 1px blue solid;
}
.menu-item.active {
    border: 1px green solid;
}

.c-sidebar-nav-icon{
    margin-top: 0.65em!important;
}

.scroll-menu-arrow {
    padding: 20px;
    cursor: pointer;
}
/*Footer*/

.footer-area{
    width: 100%;
    text-align: center;
    margin: 0 auto;
}

.horizontal-scrollable > .row {
    overflow-x: auto;
    white-space: nowrap;
}

.horizontal-scrollable > .row > .col-xs-4 {
    display: inline-block;
    float: none;
}


.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon{
    background:  #3c4b64!important;
    border:1px solid #3c4b64!important;
}



.anticon{
    vertical-align:0.15em!important;
}

h4{
    font-size: 18px;
    font-weight: normal;
    font-family: "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif;
}

h1, h2, h3, h4, h5, h6{
    color: inherit;
}

a{
    text-decoration: none;
}

img{
    vertical-align: middle;
}

.fa{
    font: normal normal normal 14px/1 FontAwesome;
    font-size: 14px;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.hidden { display:none!important;}

.ant-steps-icon .ant-steps-finish-icon{
    vertical-align: 0.1em!important;
}

.bottom-padding-none{
    margin-bottom: 0em;
}

.light-blue{
    color: #93cbf9 !important;
}

.light-blue-back{
    background: #B3D7F7;
    font-weight: bold;
    color: #000000;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .25);
    border-top:2px solid #ffffff!important;
}

.control-label{
    width: 100% !important;
}

small, .small{
    font-size: 85%;
}

.label{
    font-size: 12px;
    line-height: 1.15;
    height: 20px;
    font-weight: normal;
    display: inline-block;
    color: #000000;
    padding: .2em .6em .3em;
    text-align: center;
    vertical-align: baseline;
    white-space: nowrap;
}

.label-info, .label.label-info, .badge.badge-info, .badge-info{
    /*background-color: #3a87ad;*/
}

textarea, input[type="text"], input[type="password"], input[type="datetime"], input[type="datetime-local"], input[type="date"], input[type="month"], input[type="time"], input[type="week"], input[type="number"], input[type="email"], input[type="url"], input[type="search"], input[type="tel"], input[type="color"],
select
{
    border-radius: 0!important;
    color: #858585;
    background: #ffffff;
    border:1px solid #d5d5d5;
    padding: 5px 4px 6px;
    font-size: 14px;
    font-family: inherit;
    box-shadow: none !important;
    transition-duration: 0.1s;
    width: 100%;
}

.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12{
    float: left;
}

.mr-10{
    margin-left: 10px;
}

.pull-right{
    float: right !important;
}

.btn-danger, .btn-danger:focus{
    background: #d15b47 !important;
    border-color: #d15b47 !important;
}

.nav-header{
    background: #438EB9;
}

.ace-nav{
    margin-top: 0.8em;
    height: 100%;
}

.ace-nav > li{
    line-height: 45px;
}

.ace-nav > li > a{
    color: #FFF;
    line-height: inherit;
    text-align: center;
}

.nav{
    padding-left: 0;
    list-style: none;
}

.ace-nav .nav-user-photo{
    margin: -4px 8px 0 0;
    border-radius: 100%;
    border: 2px solid #FFF;
    max-width: 40px;
}

.user-info{
    max-width:100px;
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    vertical-align: top;
    line-height: 15px;
    position: relative;
    top: 6px;
}

.user-info small{
    display: block;
}

.ace-nav > li > a > .ace-icon{
    display: inline-block;
    font-size: 16px;
    color:#FFF;
    text-align: center;
    width: 20px;
}

.ant-layout-sider{
    background: transparent;
}

.site-layout .site-layout-background {
    background: #fff;
}

.form-horizontal .control-label{
    margin-bottom: 0;
    padding-top: 7px;
}

.no-padding-left{
    padding-left: 0!important;
}

.ant-layout-sider-trigger{
    position: fixed!important;
    bottom: 0;
    background: #ffffff;
    color: dimgray;
    border-top: 1px solid #dddddd;
}

.ant-menu-item,.ant-menu-submenu{
    border-bottom: 1px solid #dddddd;
}

/*Page Content*/
h1.smaller{
    font-size: 25px;
}

.page-header h1{
    padding: 0;
    margin: 0 8px 13px 0;
    font-weight: 400;
    color: #2679b5;
}

.widget-box{
    padding: 0;
    margin: 3px 0;
    border: 1px solid #CCC!important;
    border-radius: 10px;
    box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
}

.widget-main{
    padding: 12px 24px;
}

.widget-header{
    box-sizing: content-box;
    min-height: 40px!important;
    color: #ffffff;
    padding-left: 12px;
    /*background: #438EB9;
    border: 1px solid #438EB9;*/
    border: 1px solid #3c4b64;
    background: #3c4b64;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.widget-header > .widget-title{
    line-height: 36px;
    padding: 0;
    margin: 0;
    display: inline;
}

.qte-form{
    background: #F5F5F5;
    padding: 1em 1em 2em 1em;
}

.ant-card{
    text-align: center;
}

.ant-card-head{
    font-size: 13px;
    font-weight: bolder;
    background: #f5f5f5;
    font-family: 'Open Sans';
}

.ant-card-body{
    padding: 0!important;
}

.inme-comparison-column{
    background: #f1f1f1;
    padding: 0!important;
    margin: 0!important;
}

.inme-comparison-column p{
    width: 100%;
    border-bottom: 1px solid #DDD;
    padding-left: 1em;
    margin-top: -0.8em;
    line-height: 3em;
    font-size: 12px;
}

#InmeComparisonWidgetWrapper{
    padding-bottom: 0.7em;
}

.border-none{
    border-bottom: none!important;
}


.CompanyQuotationCoverWrapperDiv{
    text-align: center;
    float: left;
    width: 100%;
    height: 470px;
    overflow-y: hidden;
    white-space: nowrap;

}

.comparison-card{
    float: left;
    display: inline-block;
    border:1px solid #747d77;
    width: 220px;
    margin-left: 0.5em;
}


.inme-comparison-cell p{
    border-bottom: 1px solid #DDD;
    vertical-align: center;
    margin-top: -0.8em;
    line-height: 3em;
    font-size: 12px;
}

.ant-card-head{
    min-height: 30px;
    line-height: 30px;
}

.inme-comparison-column .ant-card-head{
    background: #5E7DAD;
    color: #ffffff;
    text-align: center;
}

.ant-card-head-title{
    padding: 0;
}

.btn-refresh{
    max-width: 120px;
    border-radius: 0!important;
    margin-left: 0.2em;
    margin-right: 0.5em;
}

.btn-success,.req-btn{
    border-radius: 0!important;
}

.btn-success, .btn-success:focus{
    background-color:#87b87f !important ;
    border-color:#87b87f;
}

.btn-next{
    margin-left: 0.2em;
    padding-top: -0.5em;
}

.btn .anticon{
    vertical-align: 0.1em!important;
}

.lable-text{
    font-weight: normal;
    font-size: 16px!important;
}

.form-control, select{
    color: #858585;
}

.form-control{
    font-size: 14px;
    line-height: 1.42857143;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.btn-danger, .btn-danger:focus{
    background-color: #d15b47 !important;
    border-color: #d15b47;
}

#quotation-d .control-label{
    font-size: 14px;
}

@media only screen and (max-width: 576px){

    #quotation-d .control-label,#quotation-d .label-info,
    .report-modal .control-label, .report-modal .label-info{
        text-align: center;
    }

    .modal-left{
        border:none!important;
    }

    .refresh-label,.req-qto-label{
        display: none;
    }

    .inme-comparison-cell{
        margin-left: 2em;
    }

    .fire-report-header-items div{
        margin-bottom: 0.5em;
    }
}

.report-cards{
    padding: 1em;
}

.qt-button{
    background: #D15B47;
    margin-top: -0.5em;
    width: 80%;
    color: #ffffff;
    border-color: #D15B47;
    vertical-align: 0.6em;

}

/*SideBar*/
.sidebar {
    display: none;
    position: absolute;
    min-height: 100vh;
    z-index: 999;
}

@media only screen and (min-width: 1000px){

    .sidebar {
        position: relative;
        width: calc(224px);
        display: block;
    }
}

/*Footer*/

.footer-area{
    border-top: 2px solid #dddddd;
    height: 2em;
    line-height: 3em;
}

.footer-span{
    font-weight: bold;
    color: #438EB9;
}

.blue-left-ribbon,.red-left-ribbon,.green-left-ribbon{
    color: #FFFFFF;
    font-size: 14px;
    line-height: 20px;
    margin: 10px 0;
    position: relative;
    padding-left: 0.5em;
    padding-right: 0.5em;

}

.blue-left-ribbon:after,.red-left-ribbon:after,.green-left-ribbon:after {
    content: "";
    border-style: solid;
    border-width: 0;
    height: 0;
    position: absolute;
    width: 0;
    right: -12px;
}

.red-left-ribbon{
    background-color: #D15B47;
}

.blue-left-ribbon{
    background-color: #3A87AD;
}

.green-left-ribbon{
    background-color: #65c761;
}

.red-left-ribbon:after{
    border-color: #D15B47 transparent;
}

.blue-left-ribbon:after{
    border-color: #3A87AD transparent;
}

.green-left-ribbon:after{
    border-color: #65c761 transparent;
}

.blue-left-ribbon:after,.red-left-ribbon:after,.green-left-ribbon:after,
.blue-left-ribbon:before,.red-left-ribbon:before,.green-left-ribbon:before {
    border-width: 10px 12px 9px 0;
}

.blue-left-ribbon:before {
    border-color: transparent #3A87AD;
}

.red-left-ribbon:before {
    border-color: transparent #D15B47;
}

.green-left-ribbon:before {
    border-color: transparent #65c761;
}

.fire-qt-sum-label{
    font-size: 16px;
    margin-left: 7px;
}

.fire-qt-sum-label .anticon{
    vertical-align: 0.1em!important;
}

.qte-com-img{
    max-width: 13em;
    max-height: 13em;
}

.details-card .ant-card-head{
    color: #ffffff;
    width: 100%;
    background: #438EB9!important;
    font-size: 16px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 0.2em;
}

.details-card .ant-card{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 5px 8px 24px 5px rgba(208, 216, 243, 0.6);
}

.details-card-label{
    font-size: 15px;
    font-weight: normal;
}

.error_message{
    color:red;
}

.page-pagination{
    text-align: center;
    padding-bottom: 2em;
}

.fire-report-card {
    border:none;
    cursor: pointer!important;
    transition: 0.5s;
}

.fire-report-card:hover{
    transform: scale(1.01);
    border-color: transparent;
}

.modal-left{
    border-right: 1px solid #dddddd;
}

.modal_cus_details{
    margin-bottom: 1em;
}

.incomplete-policy{
    background: red;
}

.complete-policy{
    background: greenyellow;
}

.outstanding-policy{
    background: dodgerblue;
}

.canceled-policy{
    background: black;
}

.incomplete-policy .ant-card-body{
    background:#ffe0e0;
}

.incomplete-policy .ant-card-bordered{
    border-color: #faa7a7!important;
    border-radius: 5px;
    z-index: 1;
}

.complete-policy .ant-card-head{
    color: #ffffff;
    background: #5cff96;
}

.complete-policy .ant-card-body{
    background:#e0ffeb;
}

.complete-policy .ant-card-bordered{
    border-color: #5cff96!important;
    border-radius: 5px;
    z-index: 1;
}

.reports-area .widget-box{
    border-color: transparent!important;
}

.action-button-group button{
    margin-left: 0.5em;
    border:none;
    color: #FFFFFF;
}

.action-button-group .prop-data{
    background: #629B58;
}

.action-button-group .upload-doc-btn{
    background: #E59729;
}

.action-button-group .special-rate-btn{
    background: #7C4D45;
}

.action-button-group .del-btn{
    background: #B74635;
}

.action-button-group .rate-btn{
    background: #F7D05B;
}

.action-button-group .cart-btn{
    background: #4F99C6;
}

.card-label-area{
    line-height: 1.5em;
    margin-right: 1em;
}

.card-label-area li{
    list-style: none;
    display: inline;
    margin-left: 2em;
}

.rectangle {
    height: 10px;
    width:20px;
    display: inline-block;
}

.card-label-area .incomplete-label{
    background-color: #FAA7A7;
}

.card-label-area .pending-label{
    background-color: #577bfa;
}


.card-label-area .complete-label{
    background-color: #5CFF96;
}

.fire-report-header-items{
    background: #438EB9;
    padding: 1em;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.no-results-found-box{
    text-align: center;
    min-height: 100px;
    margin-top: 8%;
}

.quotation-select-btn{
    background: #40A9FF;
    color: #FFFFFF;
    margin-top: -1.2em;
    border: none;
    margin-bottom: 0.4em;
}

.sweet-loading{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.logout_btn{
    position: absolute;
    width: 12em;
    right: 5%;
    line-height: 2.5em;
    padding: 0;
}

.vertical-center{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.clip-board{
    color: #0d86ff;
    border:2px dashed #0d86ff;
    padding: 0.5em;
    cursor: pointer;
    width: 100%;
    background-color: #add5ff;
}

.spinner{
    z-index: 1031;
}

.textField{
    -moz-appearance: textfield;
}

.textField::-webkit-outer-spin-button,
.textField::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.remove-rate-btn{
    background: red;
    border:1px solid red;
    color: #ffffff;
}

.edit-rate-btn,.add-rate-btn{
    background: #0d86ff;
    border:1px solid #0d86ff;
    color: #ffffff;
}

.card-container p {
    margin: 0;
}
.card-container > .ant-tabs-card .ant-tabs-content {

    margin-top: -16px;
}
.card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #fff;
    padding: 16px;
}
.card-container > .ant-tabs-card > .ant-tabs-nav::before {
    display: none;
}
.card-container > .ant-tabs-card .ant-tabs-tab,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}
.card-container > .ant-tabs-card .ant-tabs-tab-active,
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #fff;
    background: #fff;
}
#components-tabs-demo-card-top .code-box-demo {
    background: #f5f5f5;
    overflow: hidden;
    padding: 24px;
}
[data-theme='compact'] .card-container > .ant-tabs-card .ant-tabs-content {
    height: 120px;
    margin-top: -8px;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab {
    border-color: transparent;
    background: transparent;
}
[data-theme='dark'] #components-tabs-demo-card-top .code-box-demo {
    background: #000;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-content > .ant-tabs-tabpane {
    background: #141414;
}
[data-theme='dark'] .card-container > .ant-tabs-card .ant-tabs-tab-active {
    border-color: #141414;
    background: #141414;
}

.table_app_icon{
    max-height: 3em;
    max-width: 3em;
}

.table-search-res2 {
    display: none;
}

@media only screen and (max-width: 992px) {
    .table-search-res1 {
        display: none;
    }

    .table-search-res2 {
        display: block;
        cursor: pointer;
    }
}

.ant-pagination-options-size-changer{
    display: none!important;
}

.checkPreviousClaims{
    min-height: 5em;
    line-height: 5em;
}

.closeOutline{
    background: red;
    border:1px solid red;
    color: #ffffff;
}

.checkOutline{
    background: #1b9e3e;
    border:1px solid #1b9e3e;
    color: #ffffff;
}
